<template>
  <b-form>
    <b-row>
      <b-col>
        <h1>Page detail</h1>
        <b-form-checkbox
          v-model="page.active"
          name="active"
          class="content-switch"
          switch
          ><b>Active</b></b-form-checkbox
        >
      </b-col>
      <b-col cols="auto">
        <b-button class="mb-5 save-button" @click="save()"> Save </b-button>
      </b-col>
    </b-row>
    <b-form-group label="Link slug">
      <b-form-input v-model="page.link" />
    </b-form-group>
    <b-tabs class="mt-3">
      <b-tab v-for="language in languages" :key="language">
        <template #title>
          <country-flag :country="isoLanguage(language)" size="normal" />
        </template>
        <!-- Title / Description -->
        <b-form-group label="Title">
          <b-form-input v-model="page.title[language]"></b-form-input>
        </b-form-group>
        <b-form-group label="HTML">
          <b-form-textarea v-model="page.html[language]"></b-form-textarea>
        </b-form-group>
        <h2>Preview</h2>
        <span v-html="page.html[language]"></span>
      </b-tab>
    </b-tabs>
  </b-form>
</template>
<script>
export default {
  data() {
    return {
      page: "",
      index: "",
    };
  },
  async created() {
    if (this.$route.query.index) {
      this.index = this.$route.query.index;
      this.page = this.pages[this.index];
    } else {
      this.page = {
        active: false,
        title: {
          en_US: "",
          nl_NL: "",
        },
        link: "",
        html: {
          en_US: "",
          nl_NL: "",
        },
      };
    }
  },
  methods: {
    async save() {
      this.isLoading = true;
      if (this.$route.query.index) {
        this.pages[this.index] = this.page;
      } else {
        this.pages.push(this.page);
      }

      await this.$store.dispatch("Pages/update", this.pages);
      this.isLoading = false;
    },
    isoLanguage(language) {
      let iso = this.$i18n.t(`languages.${language}`).toLowerCase();
      if (iso == "en") {
        iso = "us";
      }
      return iso;
    },
  },
  computed: {
    pages() {
      return this.$store.state.Pages.pages;
    },
    languages() {
      return this.$store.state.Languages.all;
    },
  },
};
</script>